import img1 from '../images/common/img1.jpg'
import img2 from '../images/common/img2.jpg'
import img3 from '../images/common/img3.jpg'
import img4 from '../images/common/img4.jpg'
import img5 from '../images/common/img5.jpg'
import img6 from '../images/common/img6.jpg'
import img7 from '../images/common/img7.jpg'
import img8 from '../images/common/img8.jpg'
import img9 from '../images/common/img9.jpg'
import img10 from '../images/common/img10.jpg'
import img11 from '../images/common/img11.jpg'
import img12 from '../images/common/img12.jpg'
import img13 from '../images/common/img13.jpg'
import img14 from '../images/common/img14.jpg'
import img15 from '../images/common/img15.jpg'
import img16 from '../images/common/img16.jpg'




const dataProject = [
    {
        id: 1,
        title: 'CYBOX #01',
        img: img1,
    },
    {
        id: 2,
        title: 'CYBOX #02',
        img: img1,
    },
    {
        id: 3,
        title: 'CYBOX #03',
        img: img3,
    },
    {
        id: 4,
        title: 'CYBOX #04',
        img: img4,
    },
    {
        id: 5,
        title: 'CYBOX #05',
        img: img5,
    },
    {
        id: 6,
        title: 'CYBOX #06',
        img: img6,
    },
    {
        id: 7,
        title: 'CYBOX #07',
        img: img7,
    },
    {
        id: 8,
        title: 'CYBOX #08',
        img: img8,
    },

    {
        id: 9,
        title: 'CYBOX #09',
        img: img9,
    },
    {
        id: 10,
        title: 'CYBOX #10',
        img: img10,
    },
    {
        id: 11,
        title: 'CYBOX #11',
        img: img11,
    },
    {
        id: 12,
        title: 'CYBOX #12',
        img: img12,
    },
    {
        id: 13,
        title: 'CYBOX #13',
        img: img13,
    },
    {
        id: 14,
        title: 'CYBOX #14',
        img: img14,
    },
    {
        id: 15,
        title: 'CYBOX #15',
        img: img15,
    },
    {
        id: 16,
        title: 'CYBOX #16',
        img: img15,
    },
    // {
    //     id: 17,
    //     title: 'CYBOX #01',
    //     img: img1,
    // },
    // {
    //     id: 18,
    //     title: 'CYBOX #02',
    //     img: img2,
    // },
    // {
    //     id: 19,
    //     title: 'CYBOX #03',
    //     img: img3,
    // },
    // {
    //     id: 20,
    //     title: 'CYBOX #04',
    //     img: img4,
    // },
    // {
    //     id: 21,
    //     title: 'CYBOX #05',
    //     img: img5,
    // },
    // {
    //     id: 22,
    //     title: 'CYBOX #06',
    //     img: img6,
    // },
    // {
    //     id: 23,
    //     title: 'CYBOX #07',
    //     img: img7,
    // },
    // {
    //     id: 24,
    //     title: 'CYBOX #08',
    //     img: img2,
    // },
]

export default dataProject;